export function setLoad(val) {
    return {
      type: 'SET_MAINLOAD',
      val: val
    };
  }

  export function setLang(val) {
    return {
      type: 'SET_LANG',
      val: val
    };
  }

  export function setDarkMode(val) {
    return {
      type: 'SET_DARK',
      val: val
    };
  }

  export function setPage(val) {
    return {
      type: 'SET_PAGE',
      val: val
    };
  }

  export function setLaunch(val) {
    return {
      type: 'SET_LAUNCH',
      val: val
    };
  }

  export function setInGame(val) {
    return {
      type: 'IN_GAME',
      val: val
    };
  }

  export function setZone(val) {
    return {
      type: 'SET_ZONE',
      val: val
    };
  }

  export function switchTutor(val) {
    return {
      type: 'SETTUTOR'
    };
  }
