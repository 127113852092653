const steps = [
    // {
    //   target: '[data-tour="home-1"]',
    //   content: "Click 'Get Started' to navigate to let you know about her life."
    // },
    // {
    //   target: '[data-tour="home-2"]',
    //   content: "Or click this button when you want to go any pages by yourself."
    // },
    // {
    //   target: '[data-tour="home-3"]',
    //   content: "This site is default on Thai language. But don't worry, we also support in English language. You can keep try it!"
    // },
    // {
    //     target: '[data-tour="profile-1"]',
    //     content: "All about Kaofrang Yanisa or Kaofrang BNK48 is here!"
    //   },
    //   {
    //     target: '[data-tour="profile-2"]',
    //     content: "You can switch these tab to view another her lifestyle and job working."
    //   },
      // {
      //   target: '[data-tour="disco-1"]',
      //   position: 'bottom',
      //   content: "All about her discography of single or albums as well as various content that she has participated in the past."
      // },
      // {
      //   target: '[data-tour="disco-2"]',
      //   content: "Kaofrang's single and albums which you may know."
      // },
      // {
      //   target: '[data-tour="disco-3"]',
      //   content: "All Kaofrang's music video and her original contents will make you enjoy with her. Click 'View Content' to view that video content seamlessly."
      // },
      // {
      //   target: '[data-tour="trend"]',
      //   content: "This feature will be help to start trend on X (Twitter) easier for all about Kaofrang's event. You can click refresh button to refresh current time state."
      // },
    //   {
    //     target: '[data-tour="gallery"]',
    //     content: "Kaofrang Gallery is the another way to make rewind previous events of Kaofrang from our fanbase team. Select gallery album then here you go!"
    //   },
      {
        target: '[data-tour="event"]',
        placementBeacon: 'top',
        content: "All incoming and current Kaofrang's events which you should know are here. You can see event status, time to event is starting and also where is event at once. You can click refresh button to refresh current time state. Please don't forget to enable web push notification feature to receive new updates from Kaofrang faster!"
      },
    //   {
    //     target: '[data-tour="feed"]',
    //     content: "The another way for knowing Kaofrang's lifestyle. That is on her Instagram (Currently as @kaofrang.bnk48official). You can see highlight posts of her for you!"
    //   },
    //   {
    //     target: '[data-tour="quiz"]',
    //     content: "Game for measuring of Kaofrang's fandom. 10 shuffle questions and only one correct answer which you need to answer it!"
    //   },
    //   {
    //     target: '[data-tour="quizscore"]',
    //     content: "The world challenge of Quiz Game! You can see all scores devided by country. You can prove and see your fandom from all over the world."
    //   },
    //   {
    //     target: '[data-tour="follow-1"]',
    //     content: "The all about Following ways of Kaofrang and support community."
    //   },
    //   {
    //     target: '[data-tour="follow-2"]',
    //     content: "Follow her SNS on one of these or all!"
    //   },
    //   {
    //     target: '[data-tour="follow-3"]',
    //     content: "Let's come to join our community. These community is exclusice talk and content for all Kaofrang's lover."
    //   },
    //   {
    //     target: '[data-tour="donate-1"]',
    //     content: "All supporting have any cost. You can help fandom team to support her projects by donating. Now foreigners can donate Kaofrang's projects with scan Qr Code via your local mobile banking. Please check supported mobile banking before continue."
    //   },
    //   {
    //     target: '[data-tour="donate-2"]',
    //     content: "Select currency to check your exchange rate (Update everyday, but it may not always be accurate.)"
    //   },
    //   {
    //     target: '[data-tour="donate-3"]',
    //     content: "Select your amount (on That baht) to fix your donated amount or leave it to enter your amount by yourself on mobile banking."
    //   },
    //   {
    //     target: '[data-tour="donate-4"]',
    //     content: "Save this QR Payment Code to share your friends."
    //   }
  ];
  
  export default steps;
  