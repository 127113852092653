const initialState = {
  load: false,
  currentCountry: "",
  lang:
    localStorage.getItem("kflang") != null
      ? localStorage.getItem("kflang")
      : "th",
  dark: false,
  game: false,
  guide: localStorage.getItem("skiptutor") == null ? true : false,
  launch: null,
  country: [
    {
      name: "กรีซ",
      enName: "Greece",
      alpha2: "GR",
      alpha3: "GRC",
      numeric: "300",
      iso3166_2: "ISO 3166-2:GR",
    },
    {
      name: "กรีนแลนด์",
      enName: "Greenland",
      alpha2: "GL",
      alpha3: "GRL",
      numeric: "304",
      iso3166_2: "ISO 3166-2:GL",
    },
    {
      name: "กวม",
      enName: "Guam",
      alpha2: "GU",
      alpha3: "GUM",
      numeric: "316",
      iso3166_2: "ISO 3166-2:GU",
    },
    {
      name: "กัมพูชา",
      enName: "Cambodia",
      alpha2: "KH",
      alpha3: "KHM",
      numeric: "116",
      iso3166_2: "ISO 3166-2:KH",
    },
    {
      name: "กัวเดอลุป",
      enName: "Guadeloupe",
      alpha2: "GP",
      alpha3: "GLP",
      numeric: "312",
      iso3166_2: "ISO 3166-2:GP",
    },
    {
      name: "กัวเตมาลา",
      enName: "Guatemala",
      alpha2: "GT",
      alpha3: "GTM",
      numeric: "320",
      iso3166_2: "ISO 3166-2:GT",
    },
    {
      name: "กาตาร์",
      enName: "Qatar",
      alpha2: "QA",
      alpha3: "QAT",
      numeric: "634",
      iso3166_2: "ISO 3166-2:QA",
    },
    {
      name: "กานา",
      enName: "Ghana",
      alpha2: "GH",
      alpha3: "GHA",
      numeric: "288",
      iso3166_2: "ISO 3166-2:GH",
    },
    {
      name: "กาบอง",
      enName: "Gabon",
      alpha2: "GA",
      alpha3: "GAB",
      numeric: "266",
      iso3166_2: "ISO 3166-2:GA",
    },
    {
      name: "กาบูเวร์ดี",
      enName: "Cabo Verde",
      alpha2: "CV",
      alpha3: "CPV",
      numeric: "132",
      iso3166_2: "ISO 3166-2:CV",
    },
    {
      name: "กายอานา",
      enName: "Guyana",
      alpha2: "GY",
      alpha3: "GUY",
      numeric: "328",
      iso3166_2: "ISO 3166-2:GY",
    },
    {
      name: "กินี",
      enName: "Guinea",
      alpha2: "GN",
      alpha3: "GIN",
      numeric: "324",
      iso3166_2: "ISO 3166-2:GN",
    },
    {
      name: "กินี-บิสเซา",
      enName: "Guinea-Bissau",
      alpha2: "GW",
      alpha3: "GNB",
      numeric: "624",
      iso3166_2: "ISO 3166-2:GW",
    },
    {
      name: "กือราเซา",
      enName: "Curaçao",
      alpha2: "CW",
      alpha3: "CUW",
      numeric: "531",
      iso3166_2: "ISO 3166-2:CUW",
    },
    {
      name: "เกรเนดา",
      enName: "Grenada",
      alpha2: "GD",
      alpha3: "GRD",
      numeric: "308",
      iso3166_2: "ISO 3166-2:GD",
    },
    {
      name: "เกาหลีใต้",
      enName: "South Korea",
      alpha2: "KR",
      alpha3: "KOR",
      numeric: "410",
      iso3166_2: "ISO 3166-2:KR",
    },
    {
      name: "เกาหลีเหนือ",
      enName: "North Korea",
      alpha2: "KP",
      alpha3: "PRK",
      numeric: "408",
      iso3166_2: "ISO 3166-2:KP",
    },
    {
      name: "เกาะคริสต์มาส",
      enName: "Christmas Island",
      alpha2: "CX",
      alpha3: "CXR",
      numeric: "162",
      iso3166_2: "ISO 3166-2:CX",
    },
    {
      name: "เกาะเซาท์จอร์เจียและหมู่เกาะเซาท์แซนด์วิช",
      enName: "South Georgia and the South Sandwich Islands",
      alpha2: "GS",
      alpha3: "SGS",
      numeric: "239",
      iso3166_2: "ISO 3166-2:GS",
    },
    {
      name: "เกาะเซาท์จอร์เจียและหมู่เกาะเซาท์แซนด์วิช",
      enName: "South Sudan",
      alpha2: "SS",
      alpha3: "SSD",
      numeric: "728",
      iso3166_2: "ISO 3166-2:SS",
    },
    {
      name: "เกาะนอร์ฟอล์ก",
      enName: "Norfolk Island",
      alpha2: "NF",
      alpha3: "NFK",
      numeric: "574",
      iso3166_2: "ISO 3166-2:NF",
    },
    {
      name: "เกาะบูเวต์",
      enName: "Bouvet Island",
      alpha2: "BV",
      alpha3: "BVT",
      numeric: "74",
      iso3166_2: "ISO 3166-2:BV",
    },
    {
      name: "เกาะเล็กรอบนอกของสหรัฐอเมริกา",
      enName: "United States Minor Outlying Islands",
      alpha2: "UM",
      alpha3: "UMI",
      numeric: "581",
      iso3166_2: "ISO 3166-2:UM",
    },
    {
      name: "เกาะเฮิร์ดและหมู่เกาะแมกดอนัลด์",
      enName: "Heard Island and McDonald Islands",
      alpha2: "HM",
      alpha3: "HMD",
      numeric: "334",
      iso3166_2: "ISO 3166-2:HM",
    },
    {
      name: "เกิร์นซีย์",
      enName: "Guernsey",
      alpha2: "GG",
      alpha3: "GGY",
      numeric: "831",
      iso3166_2: "ISO 3166-2:GG",
    },
    {
      name: "แกมเบีย",
      enName: "Gambia",
      alpha2: "GM",
      alpha3: "GMB",
      numeric: "270",
      iso3166_2: "ISO 3166-2:GM",
    },
    {
      name: "โกตดิวัวร์",
      enName: "Côte d'Ivoire",
      alpha2: "CI",
      alpha3: "CIV",
      numeric: "384",
      iso3166_2: "ISO 3166-2:CI",
    },
    {
      name: "คอโมโรส",
      enName: "Comoros",
      alpha2: "KM",
      alpha3: "COM",
      numeric: "174",
      iso3166_2: "ISO 3166-2:KM",
    },
    {
      name: "คอสตาริกา",
      enName: "Costa Rica",
      alpha2: "CR",
      alpha3: "CRI",
      numeric: "188",
      iso3166_2: "ISO 3166-2:CR",
    },
    {
      name: "คาซัคสถาน",
      enName: "Kazakhstan",
      alpha2: "KZ",
      alpha3: "KAZ",
      numeric: "398",
      iso3166_2: "ISO 3166-2:KZ",
    },
    {
      name: "คิริบาส",
      enName: "Kiribati",
      alpha2: "KI",
      alpha3: "KIR",
      numeric: "296",
      iso3166_2: "ISO 3166-2:KI",
    },
    {
      name: "คิวบา",
      enName: "Cuba",
      alpha2: "CU",
      alpha3: "CUB",
      numeric: "192",
      iso3166_2: "ISO 3166-2:CU",
    },
    {
      name: "คีร์กีซสถาน",
      enName: "Kyrgyzstan",
      alpha2: "KG",
      alpha3: "KGZ",
      numeric: "417",
      iso3166_2: "ISO 3166-2:KG",
    },
    {
      name: "คูเวต",
      enName: "Kuwait",
      alpha2: "KW",
      alpha3: "KWT",
      numeric: "414",
      iso3166_2: "ISO 3166-2:KW",
    },
    {
      name: "เคนยา",
      enName: "Kenya",
      alpha2: "KE",
      alpha3: "KEN",
      numeric: "404",
      iso3166_2: "ISO 3166-2:KE",
    },
    {
      name: "แคนาดา",
      enName: "Canada",
      alpha2: "CA",
      alpha3: "CAN",
      numeric: "124",
      iso3166_2: "ISO 3166-2:CA",
    },
    {
      name: "แคเมอรูน",
      enName: "Cameroon",
      alpha2: "CM",
      alpha3: "CMR",
      numeric: "120",
      iso3166_2: "ISO 3166-2:CM",
    },
    {
      name: "โครเอเชีย",
      enName: "Croatia",
      alpha2: "HR",
      alpha3: "HRV",
      numeric: "191",
      iso3166_2: "ISO 3166-2:HR",
    },
    {
      name: "โคลอมเบีย",
      enName: "Colombia",
      alpha2: "CO",
      alpha3: "COL",
      numeric: "170",
      iso3166_2: "ISO 3166-2:CO",
    },
    {
      name: "จอร์เจีย",
      enName: "Georgia",
      alpha2: "GE",
      alpha3: "GEO",
      numeric: "268",
      iso3166_2: "ISO 3166-2:GE",
    },
    {
      name: "จอร์แดน",
      enName: "Jordan",
      alpha2: "JO",
      alpha3: "JOR",
      numeric: "400",
      iso3166_2: "ISO 3166-2:JO",
    },
    {
      name: "จาเมกา",
      enName: "Jamaica",
      alpha2: "JM",
      alpha3: "JAM",
      numeric: "388",
      iso3166_2: "ISO 3166-2:JM",
    },
    {
      name: "จิบูตี",
      enName: "Djibouti",
      alpha2: "DJ",
      alpha3: "DJI",
      numeric: "262",
      iso3166_2: "ISO 3166-2:DJ",
    },
    {
      name: "จีน",
      enName: "China",
      alpha2: "CN",
      alpha3: "CHN",
      numeric: "156",
      iso3166_2: "ISO 3166-2:CN",
    },
    {
      name: "เจอร์ซีย์",
      enName: "Jersey",
      alpha2: "JE",
      alpha3: "JEY",
      numeric: "832",
      iso3166_2: "ISO 3166-2:JE",
    },
    {
      name: "ชาด",
      enName: "Chad",
      alpha2: "TD",
      alpha3: "TCD",
      numeric: "148",
      iso3166_2: "ISO 3166-2:TD",
    },
    {
      name: "ชิลี",
      enName: "Chile",
      alpha2: "CL",
      alpha3: "CHL",
      numeric: "152",
      iso3166_2: "ISO 3166-2:CL",
    },
    {
      name: "เช็กเกีย",
      enName: "Czech Republic",
      alpha2: "CZ",
      alpha3: "CZE",
      numeric: "203",
      iso3166_2: "ISO 3166-2:CZ",
    },
    {
      name: "ซานมารีโน",
      enName: "San Marino",
      alpha2: "SM",
      alpha3: "SMR",
      numeric: "674",
      iso3166_2: "ISO 3166-2:SM",
    },
    {
      name: "ซามัว",
      enName: "Samoa",
      alpha2: "WS",
      alpha3: "WSM",
      numeric: "882",
      iso3166_2: "ISO 3166-2:WS",
    },
    {
      name: "ซาอุดีอาระเบีย",
      enName: "Saudi Arabia",
      alpha2: "SA",
      alpha3: "SAU",
      numeric: "682",
      iso3166_2: "ISO 3166-2:SA",
    },
    {
      name: "ซาฮาราตะวันตก",
      enName: "Western Sahara",
      alpha2: "EH",
      alpha3: "ESH",
      numeric: "732",
      iso3166_2: "ISO 3166-2:EH",
    },
    {
      name: "ซิมบับเว",
      enName: "Zimbabwe",
      alpha2: "ZW",
      alpha3: "ZWE",
      numeric: "716",
      iso3166_2: "ISO 3166-2:ZW",
    },
    {
      name: "ซีเรีย",
      enName: "Syrian Arab Republic",
      alpha2: "SY",
      alpha3: "SYR",
      numeric: "760",
      iso3166_2: "ISO 3166-2:SY",
    },
    {
      name: "ซูดาน",
      enName: "Sudan",
      alpha2: "SD",
      alpha3: "SDN",
      numeric: "736",
      iso3166_2: "ISO 3166-2:SD",
    },
    {
      name: "ซูรินาม",
      enName: "Suriname",
      alpha2: "SR",
      alpha3: "SUR",
      numeric: "740",
      iso3166_2: "ISO 3166-2:SR",
    },
    {
      name: "เซเชลส์",
      enName: "Seychelles",
      alpha2: "SC",
      alpha3: "SYC",
      numeric: "690",
      iso3166_2: "ISO 3166-2:SC",
    },
    {
      name: "เซนต์คิตส์และเนวิส",
      enName: "Saint Kitts and Nevis",
      alpha2: "KN",
      alpha3: "KNA",
      numeric: "659",
      iso3166_2: "ISO 3166-2:KN",
    },
    {
      name: "เซนต์ลูเชีย",
      enName: "Saint Lucia",
      alpha2: "LC",
      alpha3: "LCA",
      numeric: "662",
      iso3166_2: "ISO 3166-2:LC",
    },
    {
      name: "เซนต์วินเซนต์และเกรนาดีนส์",
      enName: "Saint Vincent and the Grenadines",
      alpha2: "VC",
      alpha3: "VCT",
      numeric: "670",
      iso3166_2: "ISO 3166-2:VC",
    },
    {
      name: "เซนต์เฮเลนา",
      enName: "Saint Helena, Ascension and Tristan da Cunha",
      alpha2: "SH",
      alpha3: "SHN",
      numeric: "654",
      iso3166_2: "ISO 3166-2:SH",
    },
    {
      name: "เซเนกัล",
      enName: "Senegal",
      alpha2: "SN",
      alpha3: "SEN",
      numeric: "686",
      iso3166_2: "ISO 3166-2:SN",
    },
    {
      name: "เซอร์เบีย",
      enName: "Serbia",
      alpha2: "RS",
      alpha3: "SRB",
      numeric: "688",
      iso3166_2: "ISO 3166-2:RS",
    },
    {
      name: "เซาตูเมและปรินซีปี",
      enName: "Sao Tome and Principe",
      alpha2: "ST",
      alpha3: "STP",
      numeric: "678",
      iso3166_2: "ISO 3166-2:ST",
    },
    {
      name: "เซียร์ราลีโอน",
      enName: "Sierra Leone",
      alpha2: "SL",
      alpha3: "SLE",
      numeric: "694",
      iso3166_2: "ISO 3166-2:SL",
    },
    {
      name: "แซงบาร์เตเลมี",
      enName: "Saint Barthélemy",
      alpha2: "BL",
      alpha3: "BLM",
      numeric: "652",
      iso3166_2: "ISO 3166-2:BL",
    },
    {
      name: "แซงปีแยร์และมีเกอลง",
      enName: "Saint Pierre and Miquelon",
      alpha2: "PM",
      alpha3: "SPM",
      numeric: "666",
      iso3166_2: "ISO 3166-2:PM",
    },
    {
      name: "แซงมาร์แตง",
      enName: "Saint Martin",
      alpha2: "MF",
      alpha3: "MAF",
      numeric: "663",
      iso3166_2: "ISO 3166-2:MF",
    },
    {
      name: "แซมเบีย",
      enName: "Zambia",
      alpha2: "ZM",
      alpha3: "ZMB",
      numeric: "894",
      iso3166_2: "ISO 3166-2:ZM",
    },
    {
      name: "โซมาเลีย",
      enName: "Somalia",
      alpha2: "SO",
      alpha3: "SOM",
      numeric: "706",
      iso3166_2: "ISO 3166-2:SO",
    },
    {
      name: "ไซปรัส",
      enName: "Cyprus",
      alpha2: "CY",
      alpha3: "CYP",
      numeric: "196",
      iso3166_2: "ISO 3166-2:CY",
    },
    {
      name: "ญี่ปุ่น",
      enName: "Japan",
      alpha2: "JP",
      alpha3: "JPN",
      numeric: "392",
      iso3166_2: "ISO 3166-2:JP",
    },
    {
      name: "ดอมินีกา",
      enName: "Dominica",
      alpha2: "DM",
      alpha3: "DMA",
      numeric: "212",
      iso3166_2: "ISO 3166-2:DM",
    },
    {
      name: "เดนมาร์ก",
      enName: "Denmark",
      alpha2: "DK",
      alpha3: "DNK",
      numeric: "208",
      iso3166_2: "ISO 3166-2:DK",
    },
    {
      name: "ตรินิแดดและโตเบโก",
      enName: "Trinidad and Tobago",
      alpha2: "TT",
      alpha3: "TTO",
      numeric: "780",
      iso3166_2: "ISO 3166-2:TT",
    },
    {
      name: "ตองงา",
      enName: "Tonga",
      alpha2: "TO",
      alpha3: "TON",
      numeric: "776",
      iso3166_2: "ISO 3166-2:TO",
    },
    {
      name: "ติมอร์-เลสเต",
      enName: "Timor-Leste",
      alpha2: "TL",
      alpha3: "TLS",
      numeric: "626",
      iso3166_2: "ISO 3166-2:TL",
    },
    {
      name: "ตุรกี",
      enName: "Turkey",
      alpha2: "TR",
      alpha3: "TUR",
      numeric: "792",
      iso3166_2: "ISO 3166-2:TR",
    },
    {
      name: "ตูนิเซีย",
      enName: "Tunisia",
      alpha2: "TN",
      alpha3: "TUN",
      numeric: "788",
      iso3166_2: "ISO 3166-2:TN",
    },
    {
      name: "ตูวาลู",
      enName: "Tuvalu",
      alpha2: "TV",
      alpha3: "TUV",
      numeric: "798",
      iso3166_2: "ISO 3166-2:TV",
    },
    {
      name: "เติร์กเมนิสถาน",
      enName: "Turkmenistan",
      alpha2: "TM",
      alpha3: "TKM",
      numeric: "795",
      iso3166_2: "ISO 3166-2:TM",
    },
    {
      name: "โตโก",
      enName: "Togo",
      alpha2: "TG",
      alpha3: "TGO",
      numeric: "768",
      iso3166_2: "ISO 3166-2:TG",
    },
    {
      name: "ไต้หวัน",
      enName: "Taiwan",
      alpha2: "TW",
      alpha3: "TWN",
      numeric: "158",
      iso3166_2: "ISO 3166-2:TW",
    },
    {
      name: "ทาจิกิสถาน",
      enName: "Tajikistan",
      alpha2: "TJ",
      alpha3: "TJK",
      numeric: "762",
      iso3166_2: "ISO 3166-2:TJ",
    },
    {
      name: "แทนซาเนีย",
      enName: "Tanzania",
      alpha2: "TZ",
      alpha3: "TZA",
      numeric: "834",
      iso3166_2: "ISO 3166-2:TZ",
    },
    {
      name: "โทเคอเลา",
      enName: "Tokelau",
      alpha2: "TK",
      alpha3: "TKL",
      numeric: "772",
      iso3166_2: "ISO 3166-2:TK",
    },
    {
      name: "ไทย",
      enName: "Thailand",
      alpha2: "TH",
      alpha3: "THA",
      numeric: "764",
      iso3166_2: "ISO 3166-2:TH",
    },
    {
      name: "นครรัฐวาติกัน",
      enName: "Vatican City State",
      alpha2: "VA",
      alpha3: "VAT",
      numeric: "336",
      iso3166_2: "ISO 3166-2:VA",
    },
    {
      name: "นอร์เวย์",
      enName: "Norway",
      alpha2: "NO",
      alpha3: "NOR",
      numeric: "578",
      iso3166_2: "ISO 3166-2:NO",
    },
    {
      name: "นามิเบีย",
      enName: "Namibia",
      alpha2: "NA",
      alpha3: "NAM",
      numeric: "516",
      iso3166_2: "ISO 3166-2:NA",
    },
    {
      name: "นาอูรู",
      enName: "Nauru",
      alpha2: "NR",
      alpha3: "NRU",
      numeric: "520",
      iso3166_2: "ISO 3166-2:NR",
    },
    {
      name: "นิการากัว",
      enName: "Nicaragua",
      alpha2: "NI",
      alpha3: "NIC",
      numeric: "558",
      iso3166_2: "ISO 3166-2:NI",
    },
    {
      name: "นิวแคลิโดเนีย",
      enName: "New Caledonia",
      alpha2: "NC",
      alpha3: "NCL",
      numeric: "540",
      iso3166_2: "ISO 3166-2:NC",
    },
    {
      name: "นิวซีแลนด์",
      enName: "New Zealand",
      alpha2: "NZ",
      alpha3: "NZL",
      numeric: "554",
      iso3166_2: "ISO 3166-2:NZ",
    },
    {
      name: "นีวเว",
      enName: "Niue",
      alpha2: "NU",
      alpha3: "NIU",
      numeric: "570",
      iso3166_2: "ISO 3166-2:NU",
    },
    {
      name: "เนเธอร์แลนด์",
      enName: "Netherlands",
      alpha2: "NL",
      alpha3: "NLD",
      numeric: "528",
      iso3166_2: "ISO 3166-2:NL",
    },
    {
      name: "เนปาล",
      enName: "Nepal",
      alpha2: "NP",
      alpha3: "NPL",
      numeric: "524",
      iso3166_2: "ISO 3166-2:NP",
    },
    {
      name: "ไนจีเรีย",
      enName: "Nigeria",
      alpha2: "NG",
      alpha3: "NGA",
      numeric: "566",
      iso3166_2: "ISO 3166-2:NG",
    },
    {
      name: "ไนเจอร์",
      enName: "Niger",
      alpha2: "NE",
      alpha3: "NER",
      numeric: "562",
      iso3166_2: "ISO 3166-2:NE",
    },
    {
      name: "บราซิล",
      enName: "Brazil",
      alpha2: "BR",
      alpha3: "BRA",
      numeric: "76",
      iso3166_2: "ISO 3166-2:BR",
    },
    {
      name: "บริติชอินเดียนโอเชียนเทร์ริทอรี",
      enName: "British Indian Ocean Territory",
      alpha2: "IO",
      alpha3: "IOT",
      numeric: "86",
      iso3166_2: "ISO 3166-2:IO",
    },
    {
      name: "บรูไน",
      enName: "Brunei Darussalam",
      alpha2: "BN",
      alpha3: "BRN",
      numeric: "96",
      iso3166_2: "ISO 3166-2:BN",
    },
    {
      name: "บอตสวานา",
      enName: "Botswana",
      alpha2: "BW",
      alpha3: "BWA",
      numeric: "72",
      iso3166_2: "ISO 3166-2:BW",
    },
    {
      name: "บอสเนียและเฮอร์เซโกวีนา",
      enName: "Bosnia and Herzegovina",
      alpha2: "BA",
      alpha3: "BIH",
      numeric: "70",
      iso3166_2: "ISO 3166-2:BA",
    },
    {
      name: "บังกลาเทศ",
      enName: "Bangladesh",
      alpha2: "BD",
      alpha3: "BGD",
      numeric: "50",
      iso3166_2: "ISO 3166-2:BD",
    },
    {
      name: "บัลแกเรีย",
      enName: "Bulgaria",
      alpha2: "BG",
      alpha3: "BGR",
      numeric: "100",
      iso3166_2: "ISO 3166-2:BG",
    },
    {
      name: "บาร์เบโดส",
      enName: "Barbados",
      alpha2: "BB",
      alpha3: "BRB",
      numeric: "52",
      iso3166_2: "ISO 3166-2:BB",
    },
    {
      name: "บาห์เรน",
      enName: "Bahrain",
      alpha2: "BH",
      alpha3: "BHR",
      numeric: "48",
      iso3166_2: "ISO 3166-2:BH",
    },
    {
      name: "บาฮามาส",
      enName: "Bahamas",
      alpha2: "BS",
      alpha3: "BHS",
      numeric: "44",
      iso3166_2: "ISO 3166-2:BS",
    },
    {
      name: "บุรุนดี",
      enName: "Burundi",
      alpha2: "BI",
      alpha3: "BDI",
      numeric: "108",
      iso3166_2: "ISO 3166-2:BI",
    },
    {
      name: "บูร์กินาฟาโซ",
      enName: "Burkina Faso",
      alpha2: "BF",
      alpha3: "BFA",
      numeric: "854",
      iso3166_2: "ISO 3166-2:BF",
    },
    {
      name: "เบนิน",
      enName: "Benin",
      alpha2: "BJ",
      alpha3: "BEN",
      numeric: "204",
      iso3166_2: "ISO 3166-2:BJ",
    },
    {
      name: "เบลเยียม",
      enName: "Belgium",
      alpha2: "BE",
      alpha3: "BEL",
      numeric: "56",
      iso3166_2: "ISO 3166-2:BE",
    },
    {
      name: "เบลารุส",
      enName: "Belarus",
      alpha2: "BY",
      alpha3: "BLR",
      numeric: "112",
      iso3166_2: "ISO 3166-2:BY",
    },
    {
      name: "เบลีซ",
      enName: "Belize",
      alpha2: "BZ",
      alpha3: "BLZ",
      numeric: "84",
      iso3166_2: "ISO 3166-2:BZ",
    },
    {
      name: "เบอร์มิวดา",
      enName: "Bermuda",
      alpha2: "BM",
      alpha3: "BMU",
      numeric: "60",
      iso3166_2: "ISO 3166-2:BM",
    },
    {
      name: "โบลิเวีย",
      enName: "Bolivia",
      alpha2: "BO",
      alpha3: "BOL",
      numeric: "68",
      iso3166_2: "ISO 3166-2:BO",
    },
    {
      name: "ปวยร์โตรีโก",
      enName: "Puerto Rico",
      alpha2: "PR",
      alpha3: "PRI",
      numeric: "630",
      iso3166_2: "ISO 3166-2:PR",
    },
    {
      name: "ปากีสถาน",
      enName: "Pakistan",
      alpha2: "PK",
      alpha3: "PAK",
      numeric: "586",
      iso3166_2: "ISO 3166-2:PK",
    },
    {
      name: "ปานามา",
      enName: "Panama",
      alpha2: "PA",
      alpha3: "PAN",
      numeric: "591",
      iso3166_2: "ISO 3166-2:PA",
    },
    {
      name: "ปาปัวนิวกินี",
      enName: "Papua New Guinea",
      alpha2: "PG",
      alpha3: "PNG",
      numeric: "598",
      iso3166_2: "ISO 3166-2:PG",
    },
    {
      name: "ปารากวัย",
      enName: "Paraguay",
      alpha2: "PY",
      alpha3: "PRY",
      numeric: "600",
      iso3166_2: "ISO 3166-2:PY",
    },
    {
      name: "ปาเลสไตน์",
      enName: "Palestine",
      alpha2: "PS",
      alpha3: "PSE",
      numeric: "275",
      iso3166_2: "ISO 3166-2:PS",
    },
    {
      name: "ปาเลา",
      enName: "Palau",
      alpha2: "PW",
      alpha3: "PLW",
      numeric: "585",
      iso3166_2: "ISO 3166-2:PW",
    },
    {
      name: "เปรู",
      enName: "Peru",
      alpha2: "PE",
      alpha3: "PER",
      numeric: "604",
      iso3166_2: "ISO 3166-2:PE",
    },
    {
      name: "โปรตุเกส",
      enName: "Portugal",
      alpha2: "PT",
      alpha3: "PRT",
      numeric: "620",
      iso3166_2: "ISO 3166-2:PT",
    },
    {
      name: "โปแลนด์",
      enName: "Poland",
      alpha2: "PL",
      alpha3: "POL",
      numeric: "616",
      iso3166_2: "ISO 3166-2:PL",
    },
    {
      name: "ฝรั่งเศส",
      enName: "France",
      alpha2: "FR",
      alpha3: "FRA",
      numeric: "250",
      iso3166_2: "ISO 3166-2:FR",
    },
    {
      name: "พม่า",
      enName: "Myanmar",
      alpha2: "MM",
      alpha3: "MMR",
      numeric: "104",
      iso3166_2: "ISO 3166-2:MM",
    },
    {
      name: "ฟินแลนด์",
      enName: "Finland",
      alpha2: "FI",
      alpha3: "FIN",
      numeric: "246",
      iso3166_2: "ISO 3166-2:FI",
    },
    {
      name: "ฟิลิปปินส์",
      enName: "Philippines",
      alpha2: "PH",
      alpha3: "PHL",
      numeric: "608",
      iso3166_2: "ISO 3166-2:PH",
    },
    {
      name: "ฟีจี",
      enName: "Fiji",
      alpha2: "FJ",
      alpha3: "FJI",
      numeric: "242",
      iso3166_2: "ISO 3166-2:FJ",
    },
    {
      name: "เฟรนช์เกียนา",
      enName: "French Guiana",
      alpha2: "GF",
      alpha3: "GUF",
      numeric: "254",
      iso3166_2: "ISO 3166-2:GF",
    },
    {
      name: "เฟรนช์เซาเทิร์นและแอนตาร์กติกแลนดส์",
      enName: "French Southern Territories",
      alpha2: "TF",
      alpha3: "ATF",
      numeric: "260",
      iso3166_2: "ISO 3166-2:TF",
    },
    {
      name: "เฟรนช์พอลินีเชีย",
      enName: "French Polynesia",
      alpha2: "PF",
      alpha3: "PYF",
      numeric: "258",
      iso3166_2: "ISO 3166-2:PF",
    },
    {
      name: "ภูฏาน",
      enName: "Bhutan",
      alpha2: "BT",
      alpha3: "BTN",
      numeric: "64",
      iso3166_2: "ISO 3166-2:BT",
    },
    {
      name: "มองโกเลีย",
      enName: "Mongolia",
      alpha2: "MN",
      alpha3: "MNG",
      numeric: "496",
      iso3166_2: "ISO 3166-2:MN",
    },
    {
      name: "มอนต์เซอร์รัต",
      enName: "Montserrat",
      alpha2: "MS",
      alpha3: "MSR",
      numeric: "500",
      iso3166_2: "ISO 3166-2:MS",
    },
    {
      name: "มอนเตเนโกร",
      enName: "Montenegro",
      alpha2: "ME",
      alpha3: "MNE",
      numeric: "499",
      iso3166_2: "ISO 3166-2:ME",
    },
    {
      name: "มอริเชียส",
      enName: "Mauritius",
      alpha2: "MU",
      alpha3: "MUS",
      numeric: "480",
      iso3166_2: "ISO 3166-2:MU",
    },
    {
      name: "มอริเตเนีย",
      enName: "Mauritania",
      alpha2: "MR",
      alpha3: "MRT",
      numeric: "478",
      iso3166_2: "ISO 3166-2:MR",
    },
    {
      name: "มอลโดวา",
      enName: "Moldova",
      alpha2: "MD",
      alpha3: "MDA",
      numeric: "498",
      iso3166_2: "ISO 3166-2:MD",
    },
    {
      name: "มอลตา",
      enName: "Malta",
      alpha2: "MT",
      alpha3: "MLT",
      numeric: "470",
      iso3166_2: "ISO 3166-2:MT",
    },
    {
      name: "มัลดีฟส์",
      enName: "Maldives",
      alpha2: "MV",
      alpha3: "MDV",
      numeric: "462",
      iso3166_2: "ISO 3166-2:MV",
    },
    {
      name: "มาเก๊า",
      enName: "Macao",
      alpha2: "MO",
      alpha3: "MAC",
      numeric: "446",
      iso3166_2: "ISO 3166-2:MO",
    },
    {
      name: "นอร์ทมาซิโดเนีย",
      enName: "North Macedonia",
      alpha2: "MK",
      alpha3: "MKD",
      numeric: "807",
      iso3166_2: "ISO 3166-2:MK",
    },
    {
      name: "มาดากัสการ์",
      enName: "Madagascar",
      alpha2: "MG",
      alpha3: "MDG",
      numeric: "450",
      iso3166_2: "ISO 3166-2:MG",
    },
    {
      name: "มายอต",
      enName: "Mayotte",
      alpha2: "YT",
      alpha3: "MYT",
      numeric: "175",
      iso3166_2: "ISO 3166-2:YT",
    },
    {
      name: "มาร์ตีนิก",
      enName: "Martinique",
      alpha2: "MQ",
      alpha3: "MTQ",
      numeric: "474",
      iso3166_2: "ISO 3166-2:MQ",
    },
    {
      name: "มาลาวี",
      enName: "Malawi",
      alpha2: "MW",
      alpha3: "MWI",
      numeric: "454",
      iso3166_2: "ISO 3166-2:MW",
    },
    {
      name: "มาลี",
      enName: "Mali",
      alpha2: "ML",
      alpha3: "MLI",
      numeric: "466",
      iso3166_2: "ISO 3166-2:ML",
    },
    {
      name: "มาเลเซีย",
      enName: "Malaysia",
      alpha2: "MY",
      alpha3: "MYS",
      numeric: "458",
      iso3166_2: "ISO 3166-2:MY",
    },
    {
      name: "เม็กซิโก",
      enName: "Mexico",
      alpha2: "MX",
      alpha3: "MEX",
      numeric: "484",
      iso3166_2: "ISO 3166-2:MX",
    },
    {
      name: "โมซัมบิก",
      enName: "Mozambique",
      alpha2: "MZ",
      alpha3: "MOZ",
      numeric: "508",
      iso3166_2: "ISO 3166-2:MZ",
    },
    {
      name: "โมนาโก",
      enName: "Monaco",
      alpha2: "MC",
      alpha3: "MCO",
      numeric: "492",
      iso3166_2: "ISO 3166-2:MC",
    },
    {
      name: "โมร็อกโก",
      enName: "Morocco",
      alpha2: "MA",
      alpha3: "MAR",
      numeric: "504",
      iso3166_2: "ISO 3166-2:MA",
    },
    {
      name: "ไมโครนีเซีย",
      enName: "Micronesia",
      alpha2: "FM",
      alpha3: "FSM",
      numeric: "583",
      iso3166_2: "ISO 3166-2:FM",
    },
    {
      name: "ยิบรอลตาร์",
      enName: "Gibraltar",
      alpha2: "GI",
      alpha3: "GIB",
      numeric: "292",
      iso3166_2: "ISO 3166-2:GI",
    },
    {
      name: "ยูกันดา",
      enName: "Uganda",
      alpha2: "UG",
      alpha3: "UGA",
      numeric: "800",
      iso3166_2: "ISO 3166-2:UG",
    },
    {
      name: "ยูเครน",
      enName: "Ukraine",
      alpha2: "UA",
      alpha3: "UKR",
      numeric: "804",
      iso3166_2: "ISO 3166-2:UA",
    },
    {
      name: "เยเมน",
      enName: "Yemen",
      alpha2: "YE",
      alpha3: "YEM",
      numeric: "887",
      iso3166_2: "ISO 3166-2:YE",
    },
    {
      name: "เยอรมนี",
      enName: "Germany",
      alpha2: "DE",
      alpha3: "DEU",
      numeric: "276",
      iso3166_2: "ISO 3166-2:DE",
    },
    {
      name: "รวันดา",
      enName: "Rwanda",
      alpha2: "RW",
      alpha3: "RWA",
      numeric: "646",
      iso3166_2: "ISO 3166-2:RW",
    },
    {
      name: "รัสเซีย",
      enName: "Russian Federation",
      alpha2: "RU",
      alpha3: "RUS",
      numeric: "643",
      iso3166_2: "ISO 3166-2:RU",
    },
    {
      name: "เรอูว์นียง",
      enName: "Réunion",
      alpha2: "RE",
      alpha3: "REU",
      numeric: "638",
      iso3166_2: "ISO 3166-2:RE",
    },
    {
      name: "โรมาเนีย",
      enName: "Romania",
      alpha2: "RO",
      alpha3: "ROU",
      numeric: "642",
      iso3166_2: "ISO 3166-2:RO",
    },
    {
      name: "ลักเซมเบิร์ก",
      enName: "Luxembourg",
      alpha2: "LU",
      alpha3: "LUX",
      numeric: "442",
      iso3166_2: "ISO 3166-2:LU",
    },
    {
      name: "ลัตเวีย",
      enName: "Latvia",
      alpha2: "LV",
      alpha3: "LVA",
      numeric: "428",
      iso3166_2: "ISO 3166-2:LV",
    },
    {
      name: "ลาว",
      enName: "Laos",
      alpha2: "LA",
      alpha3: "LAO",
      numeric: "418",
      iso3166_2: "ISO 3166-2:LA",
    },
    {
      name: "ลิกเตนสไตน์",
      enName: "Liechtenstein",
      alpha2: "LI",
      alpha3: "LIE",
      numeric: "438",
      iso3166_2: "ISO 3166-2:LI",
    },
    {
      name: "ลิทัวเนีย",
      enName: "Lithuania",
      alpha2: "LT",
      alpha3: "LTU",
      numeric: "440",
      iso3166_2: "ISO 3166-2:LT",
    },
    {
      name: "ลิเบีย",
      enName: "Libya",
      alpha2: "LY",
      alpha3: "LBY",
      numeric: "434",
      iso3166_2: "ISO 3166-2:LY",
    },
    {
      name: "เลโซโท",
      enName: "Lesotho",
      alpha2: "LS",
      alpha3: "LSO",
      numeric: "426",
      iso3166_2: "ISO 3166-2:LS",
    },
    {
      name: "เลบานอน",
      enName: "Lebanon",
      alpha2: "LB",
      alpha3: "LBN",
      numeric: "422",
      iso3166_2: "ISO 3166-2:LB",
    },
    {
      name: "ไลบีเรีย",
      enName: "Liberia",
      alpha2: "LR",
      alpha3: "LBR",
      numeric: "430",
      iso3166_2: "ISO 3166-2:LR",
    },
    {
      name: "วานูวาตู",
      enName: "Vanuatu",
      alpha2: "VU",
      alpha3: "VUT",
      numeric: "548",
      iso3166_2: "ISO 3166-2:VU",
    },
    {
      name: "วาลิสและฟูตูนา",
      enName: "Wallis and Futuna",
      alpha2: "WF",
      alpha3: "WLF",
      numeric: "876",
      iso3166_2: "ISO 3166-2:WF",
    },
    {
      name: "เวเนซุเอลา",
      enName: "Venezuela",
      alpha2: "VE",
      alpha3: "VEN",
      numeric: "862",
      iso3166_2: "ISO 3166-2:VE",
    },
    {
      name: "เวียดนาม",
      enName: "Vietnam",
      alpha2: "VN",
      alpha3: "VNM",
      numeric: "704",
      iso3166_2: "ISO 3166-2:VN",
    },
    {
      name: "ศรีลังกา",
      enName: "Sri Lanka",
      alpha2: "LK",
      alpha3: "LKA",
      numeric: "144",
      iso3166_2: "ISO 3166-2:LK",
    },
    {
      name: "สเปน",
      enName: "Spain",
      alpha2: "ES",
      alpha3: "ESP",
      numeric: "724",
      iso3166_2: "ISO 3166-2:ES",
    },
    {
      name: "สโลวาเกีย",
      enName: "Slovakia",
      alpha2: "SK",
      alpha3: "SVK",
      numeric: "703",
      iso3166_2: "ISO 3166-2:SK",
    },
    {
      name: "สโลวีเนีย",
      enName: "Slovenia",
      alpha2: "SI",
      alpha3: "SVN",
      numeric: "705",
      iso3166_2: "ISO 3166-2:SI",
    },
    {
      name: "สวาซิแลนด์",
      enName: "Swaziland",
      alpha2: "SZ",
      alpha3: "SWZ",
      numeric: "748",
      iso3166_2: "ISO 3166-2:SZ",
    },
    {
      name: "สวิตเซอร์แลนด์",
      enName: "Switzerland",
      alpha2: "CH",
      alpha3: "CHE",
      numeric: "756",
      iso3166_2: "ISO 3166-2:CH",
    },
    {
      name: "สวีเดน",
      enName: "Sweden",
      alpha2: "SE",
      alpha3: "SWE",
      numeric: "752",
      iso3166_2: "ISO 3166-2:SE",
    },
    {
      name: "สหรัฐ",
      enName: "United States of America",
      alpha2: "US",
      alpha3: "USA",
      numeric: "840",
      iso3166_2: "ISO 3166-2:US",
    },
    {
      name: "สหรัฐอาหรับเอมิเรตส์",
      enName: "United Arab Emirates",
      alpha2: "AE",
      alpha3: "ARE",
      numeric: "784",
      iso3166_2: "ISO 3166-2:AE",
    },
    {
      name: "สหราชอาณาจักร",
      enName: "United Kingdom of Great Britain and Northern Ireland",
      alpha2: "GB",
      alpha3: "GBR",
      numeric: "826",
      iso3166_2: "ISO 3166-2:GB",
    },
    {
      name: "สาธารณรัฐคองโก",
      enName: "Republic of the Congo",
      alpha2: "CG",
      alpha3: "COG",
      numeric: "178",
      iso3166_2: "ISO 3166-2:CG",
    },
    {
      name: "สาธารณรัฐโดมินิกัน",
      enName: "Dominican Republic",
      alpha2: "DO",
      alpha3: "DOM",
      numeric: "214",
      iso3166_2: "ISO 3166-2:DO",
    },
    {
      name: "สาธารณรัฐประชาธิปไตยคองโก",
      enName: "Democratic Republic of the Congo",
      alpha2: "CD",
      alpha3: "COD",
      numeric: "180",
      iso3166_2: "ISO 3166-2:CD",
    },
    {
      name: "สาธารณรัฐแอฟริกากลาง",
      enName: "Central African Republic",
      alpha2: "CF",
      alpha3: "CAF",
      numeric: "140",
      iso3166_2: "ISO 3166-2:CF",
    },
    {
      name: "สิงคโปร์",
      enName: "Singapore",
      alpha2: "SG",
      alpha3: "SGP",
      numeric: "702",
      iso3166_2: "ISO 3166-2:SG",
    },
    {
      name: "หมู่เกาะคุก",
      enName: "Cook Islands",
      alpha2: "CK",
      alpha3: "COK",
      numeric: "184",
      iso3166_2: "ISO 3166-2:CK",
    },
    {
      name: "หมู่เกาะเคย์แมน",
      enName: "Cayman Islands",
      alpha2: "KY",
      alpha3: "CYM",
      numeric: "136",
      iso3166_2: "ISO 3166-2:KY",
    },
    {
      name: "หมู่เกาะโคโคส",
      enName: "Cocos (Keeling) Islands",
      alpha2: "CC",
      alpha3: "CCK",
      numeric: "166",
      iso3166_2: "ISO 3166-2:CC",
    },
    {
      name: "หมู่เกาะโซโลมอน",
      enName: "Solomon Islands",
      alpha2: "SB",
      alpha3: "SLB",
      numeric: "90",
      iso3166_2: "ISO 3166-2:SB",
    },
    {
      name: "หมู่เกาะเติกส์และเคคอส",
      enName: "Turks and Caicos Islands",
      alpha2: "TC",
      alpha3: "TCA",
      numeric: "796",
      iso3166_2: "ISO 3166-2:TC",
    },
    {
      name: "หมู่เกาะนอร์เทิร์นมาเรียนา",
      enName: "Northern Mariana Islands",
      alpha2: "MP",
      alpha3: "MNP",
      numeric: "580",
      iso3166_2: "ISO 3166-2:MP",
    },
    {
      name: "หมู่เกาะบริติชเวอร์จิน",
      enName: "British Virgin Islands",
      alpha2: "VG",
      alpha3: "VGB",
      numeric: "92",
      iso3166_2: "ISO 3166-2:VG",
    },
    {
      name: "หมู่เกาะพิตแคร์น",
      enName: "Pitcairn",
      alpha2: "PN",
      alpha3: "PCN",
      numeric: "612",
      iso3166_2: "ISO 3166-2:PN",
    },
    {
      name: "หมู่เกาะฟอล์กแลนด์",
      enName: "Falkland Islands",
      alpha2: "FK",
      alpha3: "FLK",
      numeric: "238",
      iso3166_2: "ISO 3166-2:FK",
    },
    {
      name: "หมู่เกาะแฟโร",
      enName: "Faroe Islands",
      alpha2: "FO",
      alpha3: "FRO",
      numeric: "234",
      iso3166_2: "ISO 3166-2:FO",
    },
    {
      name: "หมู่เกาะมาร์แชลล์",
      enName: "Marshall Islands",
      alpha2: "MH",
      alpha3: "MHL",
      numeric: "584",
      iso3166_2: "ISO 3166-2:MH",
    },
    {
      name: "หมู่เกาะเวอร์จินของสหรัฐอเมริกา",
      enName: "United States Virgin Islands",
      alpha2: "VI",
      alpha3: "VIR",
      numeric: "850",
      iso3166_2: "ISO 3166-2:VI",
    },
    {
      name: "หมู่เกาะโอลันด์",
      enName: "Åland Islands",
      alpha2: "AX",
      alpha3: "ALA",
      numeric: "248",
      iso3166_2: "ISO 3166-2:AX",
    },
    {
      name: "อเมริกันซามัว",
      enName: "American Samoa",
      alpha2: "AS",
      alpha3: "ASM",
      numeric: "16",
      iso3166_2: "ISO 3166-2:AS",
    },
    {
      name: "ออสเตรเลีย",
      enName: "Australia",
      alpha2: "AU",
      alpha3: "AUS",
      numeric: "36",
      iso3166_2: "ISO 3166-2:AU",
    },
    {
      name: "ออสเตรีย",
      enName: "Austria",
      alpha2: "AT",
      alpha3: "AUT",
      numeric: "40",
      iso3166_2: "ISO 3166-2:AT",
    },
    {
      name: "อันดอร์รา",
      enName: "Andorra",
      alpha2: "AD",
      alpha3: "AND",
      numeric: "20",
      iso3166_2: "ISO 3166-2:AD",
    },
    {
      name: "อัฟกานิสถาน",
      enName: "Afghanistan",
      alpha2: "AF",
      alpha3: "AFG",
      numeric: "4",
      iso3166_2: "ISO 3166-2:AF",
    },
    {
      name: "อาเซอร์ไบจาน",
      enName: "Azerbaijan",
      alpha2: "AZ",
      alpha3: "AZE",
      numeric: "31",
      iso3166_2: "ISO 3166-2:AZ",
    },
    {
      name: "อาร์เจนตินา",
      enName: "Argentina",
      alpha2: "AR",
      alpha3: "ARG",
      numeric: "32",
      iso3166_2: "ISO 3166-2:AR",
    },
    {
      name: "อาร์มีเนีย",
      enName: "Armenia",
      alpha2: "AM",
      alpha3: "ARM",
      numeric: "51",
      iso3166_2: "ISO 3166-2:AM",
    },
    {
      name: "อารูบา",
      enName: "Aruba",
      alpha2: "AW",
      alpha3: "ABW",
      numeric: "533",
      iso3166_2: "ISO 3166-2:AW",
    },
    {
      name: "อิเควทอเรียลกินี",
      enName: "Equatorial Guinea",
      alpha2: "GQ",
      alpha3: "GNQ",
      numeric: "226",
      iso3166_2: "ISO 3166-2:GQ",
    },
    {
      name: "อิตาลี",
      enName: "Italy",
      alpha2: "IT",
      alpha3: "ITA",
      numeric: "380",
      iso3166_2: "ISO 3166-2:IT",
    },
    {
      name: "อินเดีย",
      enName: "India",
      alpha2: "IN",
      alpha3: "IND",
      numeric: "356",
      iso3166_2: "ISO 3166-2:IN",
    },
    {
      name: "อินโดนีเซีย",
      enName: "Indonesia",
      alpha2: "ID",
      alpha3: "IDN",
      numeric: "360",
      iso3166_2: "ISO 3166-2:ID",
    },
    {
      name: "อิรัก",
      enName: "Iraq",
      alpha2: "IQ",
      alpha3: "IRQ",
      numeric: "368",
      iso3166_2: "ISO 3166-2:IQ",
    },
    {
      name: "อิสราเอล",
      enName: "Israel",
      alpha2: "IL",
      alpha3: "ISR",
      numeric: "376",
      iso3166_2: "ISO 3166-2:IL",
    },
    {
      name: "อิหร่าน",
      enName: "Iran",
      alpha2: "IR",
      alpha3: "IRN",
      numeric: "364",
      iso3166_2: "ISO 3166-2:IR",
    },
    {
      name: "อียิปต์",
      enName: "Egypt",
      alpha2: "EG",
      alpha3: "EGY",
      numeric: "818",
      iso3166_2: "ISO 3166-2:EG",
    },
    {
      name: "อุซเบกิสถาน",
      enName: "Uzbekistan",
      alpha2: "UZ",
      alpha3: "UZB",
      numeric: "860",
      iso3166_2: "ISO 3166-2:UZ",
    },
    {
      name: "อุรุกวัย",
      enName: "Uruguay",
      alpha2: "UY",
      alpha3: "URY",
      numeric: "858",
      iso3166_2: "ISO 3166-2:UY",
    },
    {
      name: "เอกวาดอร์",
      enName: "Ecuador",
      alpha2: "EC",
      alpha3: "ECU",
      numeric: "218",
      iso3166_2: "ISO 3166-2:EC",
    },
    {
      name: "เอธิโอเปีย",
      enName: "Ethiopia",
      alpha2: "ET",
      alpha3: "ETH",
      numeric: "231",
      iso3166_2: "ISO 3166-2:ET",
    },
    {
      name: "เอริเทรีย",
      enName: "Eritrea",
      alpha2: "ER",
      alpha3: "ERI",
      numeric: "232",
      iso3166_2: "ISO 3166-2:ER",
    },
    {
      name: "เอลซัลวาดอร์",
      enName: "El Salvador",
      alpha2: "SV",
      alpha3: "SLV",
      numeric: "222",
      iso3166_2: "ISO 3166-2:SV",
    },
    {
      name: "เอสโตเนีย",
      enName: "Estonia",
      alpha2: "EE",
      alpha3: "EST",
      numeric: "233",
      iso3166_2: "ISO 3166-2:EE",
    },
    {
      name: "แองกวิลลา",
      enName: "Anguilla",
      alpha2: "AI",
      alpha3: "AIA",
      numeric: "660",
      iso3166_2: "ISO 3166-2:AI",
    },
    {
      name: "แองโกลา",
      enName: "Angola",
      alpha2: "AO",
      alpha3: "AGO",
      numeric: "24",
      iso3166_2: "ISO 3166-2:AO",
    },
    {
      name: "แอนตาร์กติกา",
      enName: "Antarctica",
      alpha2: "AQ",
      alpha3: "ATA",
      numeric: "10",
      iso3166_2: "ISO 3166-2:AQ",
    },
    {
      name: "แอนติกาและบาร์บูดา",
      enName: "Antigua and Barbuda",
      alpha2: "AG",
      alpha3: "ATG",
      numeric: "28",
      iso3166_2: "ISO 3166-2:AG",
    },
    {
      name: "แอฟริกาใต้",
      enName: "South Africa",
      alpha2: "ZA",
      alpha3: "ZAF",
      numeric: "710",
      iso3166_2: "ISO 3166-2:ZA",
    },
    {
      name: "แอลจีเรีย",
      enName: "Algeria",
      alpha2: "DZ",
      alpha3: "DZA",
      numeric: "12",
      iso3166_2: "ISO 3166-2:DZ",
    },
    {
      name: "แอลเบเนีย",
      enName: "Albania",
      alpha2: "AL",
      alpha3: "ALB",
      numeric: "8",
      iso3166_2: "ISO 3166-2:AL",
    },
    {
      name: "โอมาน",
      enName: "Oman",
      alpha2: "OM",
      alpha3: "OMN",
      numeric: "512",
      iso3166_2: "ISO 3166-2:OM",
    },
    {
      name: "ไอซ์แลนด์",
      enName: "Iceland",
      alpha2: "IS",
      alpha3: "ISL",
      numeric: "352",
      iso3166_2: "ISO 3166-2:IS",
    },
    {
      name: "ไอร์แลนด์",
      enName: "Ireland",
      alpha2: "IE",
      alpha3: "IRL",
      numeric: "372",
      iso3166_2: "ISO 3166-2:IE",
    },
    {
      name: "ไอล์ออฟแมน",
      enName: "Isle of Man",
      alpha2: "IM",
      alpha3: "IMN",
      numeric: "833",
      iso3166_2: "ISO 3166-2:IM",
    },
    {
      name: "ฮ่องกง",
      enName: "Hong Kong",
      alpha2: "HK",
      alpha3: "HKG",
      numeric: "344",
      iso3166_2: "ISO 3166-2:HK",
    },
    {
      name: "ฮอนดูรัส",
      enName: "Honduras",
      alpha2: "HN",
      alpha3: "HND",
      numeric: "340",
      iso3166_2: "ISO 3166-2:HN",
    },
    {
      name: "ฮังการี",
      enName: "Hungary",
      alpha2: "HU",
      alpha3: "HUN",
      numeric: "348",
      iso3166_2: "ISO 3166-2:HU",
    },
    {
      name: "เฮติ",
      enName: "Haiti",
      alpha2: "HT",
      alpha3: "HTI",
      numeric: "332",
      iso3166_2: "ISO 3166-2:HT",
    },
    {
      name: "ซินต์มาร์เติน",
      enName: "Sint Maarten",
      alpha2: "SX",
      alpha3: "SXM",
      numeric: "534",
      iso3166_2: "ISO 3166-2:SX",
    },
    {
      name: "เนเธอร์แลนด์แคริบเบียน",
      enName: "Bonaire, Sint Eustatius and Saba",
      alpha2: "BQ",
      alpha3: "BES",
      numeric: "535",
      iso3166_2: "ISO 3166-2:BQ",
    },
    {
      name: "สฟาลบาร์และยานไมเอน",
      enName: "Svalbard and Jan Mayen",
      alpha2: "SJ",
      alpha3: "SJM",
      numeric: "744",
      iso3166_2: "ISO 3166-2:SJ",
    },
  ],
  currentPage: "Loading",
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_MAINLOAD":
      return { ...state, load: action.val };
    case "SET_ZONE":
      return { ...state, currentCountry: action.val };
    case "SET_LANG":
      return { ...state, lang: action.val };
    case "SET_DARK":
      return { ...state, dark: action.val };
    case "SET_PAGE":
      return { ...state, currentPage: action.val };
    case "SET_LAUNCH":
      return { ...state, launch: action.val };
    case "IN_GAME":
      return { ...state, game: action.val };
    case "SETTUTOR":
      if (state.guide) {
        localStorage.setItem("skiptutor", new Date());
      } else {
        localStorage.removeItem("skiptutor");
      }
      return { ...state, guide: !state.guide };
    default:
      return state;
  }
}

export default Reducer;
